import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Home from 'pages/Home'
import About from 'pages/About'
import Services from 'pages/Services'
import Blog from 'pages/Blog'
import NotFound from 'pages/NotFound'

const routes = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/sobre', element: <About /> },
  { path: '/servicos', element: <Services /> },
  // { path: '/blog', element: <Blog /> },
  { path: '*', element: <NotFound /> },
])

export default function App() {
  return <RouterProvider router={routes} />
}
