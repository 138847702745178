import axios from 'axios';

async function saveContacts(data) {
  try {
    const response = await axios.post('https://dorac.com.br/api/create-deal', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('Envio do formulário bem-sucedido:', response.data);

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Erro no envio do formulário:', error.response.data); // Detalhes específicos do erro do servidor
    } else if (error.request) {
      console.error('Erro na requisição:', error.request);
    } else {
      console.error('Erro ao configurar a requisição:', error.message);
    }
    throw error;
  }
}

export { saveContacts };
