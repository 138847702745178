import React, { useEffect, useState } from 'react'
import logo from 'assets/icons/logo.svg'
import menu from 'assets/icons/menu.svg'
import Navigation from 'components/Navigation'
import { Link } from 'react-router-dom'

function Header() {
  const [textIndex, setTextIndex] = useState(0)
  const [showNavigation, setShowNavigation] = useState(false)
  const [isHeaderVisible, setIsHeaderVisible] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)
  const texts = [
    'Humanize com o novo marketing',
    'um movimento que coloca as pessoas no centro da estratégia',
    'Conheça a Comunidade Dorac',
    'A primeira do Brasil com método exclusivo!',
    'Autenticidade, intencionalidade e leveza',
    'para gerar experiências significativas',
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [texts.length])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      if (Math.abs(currentScrollY - lastScrollY) > 200) {
        setIsHeaderVisible(currentScrollY < lastScrollY)
        setLastScrollY(currentScrollY)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [lastScrollY])

  const handleClick = () => setShowNavigation(!showNavigation)

  return (
    <>
      <header
        className={`bg-filter fixed top-0 z-30 flex w-full flex-col bg-purple transition-all duration-300 ${
          isHeaderVisible ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <nav className='flex items-center justify-between px-4 py-6 text-white md:px-12'>
          <Link to='/'>
            <figure className='flex flex-row items-center gap-2 hover:opacity-75 md:flex-col'>
              <img src={logo} alt='Logo da Agência Dorac, formado pelas letras A e D estilizadas' className='w-12' />
              <figcaption className='text-xl font-semibold uppercase'>Agência Dorac</figcaption>
            </figure>
          </Link>

          <ul className='hidden items-center gap-8 md:flex'>
            <li>
              <Link className='text-xl uppercase transition-colors duration-500 hover:text-green' to='/servicos'>
                Serviços
              </Link>
            </li>
            <li>
              <Link to='/sobre' className='text-xl uppercase transition-colors duration-500 hover:text-green'>
                Sobre
              </Link>
            </li>
            <li>
              <a
                className='text-xl uppercase transition-colors duration-500 hover:text-green'
                href='https://samantha-dorabiato.kpages.online/nova-pagina-0f18db55-e474-4929-b728-ca9d44d907f0'
                target='_blank'
              >
                Educação
              </a>
            </li>
            <li>
              <Link className='text-xl uppercase transition-colors duration-500 hover:text-green' to='/blog'>
                Blog
              </Link>
            </li>
          </ul>

          <div className='hidden items-center gap-4 md:flex'>
            <a
              className='rounded-full bg-green px-10 py-3 text-2xl font-medium uppercase text-purple hover:opacity-75'
              href='https://api.whatsapp.com/send?phone=5543999024775&text=Ol%C3%A1.%20Vim%20pelo%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20DORAC.'
              target='_blank'
              rel='noopener noreferrer'
            >
              Contato
            </a>
          </div>

          <button className='md:hidden' onClick={handleClick}>
            <img src={menu} alt='Ícone de Menu Hamburger' className='w-8' />
          </button>
        </nav>

        <section className='flex w-full justify-center overflow-hidden bg-green p-3'>
          <a
            href='https://samantha-dorabiato.kpages.online/nova-pagina-0f18db55-e474-4929-b728-ca9d44d907f0'
            className='w-full animate-carousel text-center text-xs font-medium uppercase text-purple md:text-lg md:uppercase'
          >
            {texts[textIndex]}
          </a>
        </section>
      </header>

      <Navigation isOpen={showNavigation} onClose={() => setShowNavigation(false)} />
    </>
  )
}

export default Header