import React, { useState, useRef, useEffect } from 'react';
import { saveContacts } from 'services/formService';
import SuccessModal from '../SuccessModal';

function ContactForm() {
  const [currentSection, setCurrentSection] = useState(1);
  const [submitButtonText, setSubmitButtonText] = useState('Próximo');
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formSuccessMessage, setFormSuccessMessage] = useState('');

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    company: '',
    position: '',
    segment: '',
    employees: '',
    revenue: '',
    message: '',
  });

  const section1FirstInput = useRef(null);
  const section2FirstInput = useRef(null);
  const section3FirstInput = useRef(null);

  useEffect(() => {
    if (currentSection === 1) {
      section1FirstInput.current.focus();
    } else if (currentSection === 2) {
      section2FirstInput.current.focus();
    } else if (currentSection === 3) {
      section3FirstInput.current.focus();
    }
  }, [currentSection]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ['fullName', 'email', 'phone', 'company', 'position', 'segment', 'employees', 'revenue', 'message'];
    const isValid = requiredFields.every(field => formData[field]);

    if (!isValid) {
      setFormError('Por favor, preencha todos os campos.');
      return;
    }

    try {
      const formattedData = {
        name: 'Negociações Agência',
        deal_stage_id: '668880a9088722001292d81e',
        user_id: '6688805bc17807000fa7b711',
        contacts: [
          {
            contact_custom_fields: [
              { custom_field_id: '668d9db732ef7200186f6200', value: formData.segment.toString() },
              { custom_field_id: '668d9dcf437a7d0018ab571f', value: formData.revenue.toString() },
              { custom_field_id: '668d9e433c9a3d001346ce2e', value: formData.employees.toString() },
              { custom_field_id: '668d9e65b5d7c700136ff92d', value: formData.message.toString() }
            ],
            emails: [{ email: formData.email.toString() }],
            name: formData.fullName.toString(),
            phones: [{ phone: formData.phone.toString() }],
            title: formData.position.toString(),
            organization_id: formData.company.toString()
          }
        ],
        deal_pipeline_id: '668880a9088722001292d81c',
        deal_source: { _id: '668880a9088722001292d811' }
      };

      const response = await saveContacts(formattedData);
      setFormSuccess(true);

      const firstName = formData.fullName.split(' ')[0];
      setFormSuccessMessage(`Formulário enviado com sucesso, ${firstName}!`);

      setTimeout(() => {
        setFormSuccess(false);
        setFormSuccessMessage('');
        setSubmitButtonText('Próximo');
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          company: '',
          position: '',
          segment: '',
          employees: '',
          revenue: '',
          message: '',
        });
        setCurrentSection(1);
      }, 3000);
    } catch (error) {
      setFormError('Erro ao enviar o formulário. Tente novamente mais tarde.');
    }
  };

  const handleNextSection = () => {
    const currentFields = Object.keys(formData).slice((currentSection - 1) * 3, currentSection * 3);
    const hasEmptyFields = currentFields.some(field => !formData[field]);

    if (hasEmptyFields) {
      setFormError('Por favor, preencha todos os campos.');
    } else {
      if (currentSection === 2) {
        setSubmitButtonText('Enviar');
      }
      setCurrentSection((prevSection) => prevSection + 1);
      setFormError(null);
    }
  };

  const handleCloseModal = () => {
    setFormSuccess(false);
  };

  return (
    <section className='flex justify-center bg-purple bg-opacity-0'>
      <form
        id='Contato Formulário'
        name='Contato Formulário'
        className='m-8 flex w-full flex-col gap-4 md:w-1/2'
        onSubmit={handleSubmit}
      >
        <header>
          <h2 className='text-3xl uppercase font-semibold text-white md:text-5xl'>Entre em Contato</h2>
        </header>

        {currentSection === 1 && (
          <div className='animate-slideInRight my-4 flex flex-col gap-4'>
            <InputField
              ref={section1FirstInput}
              id='fullName'
              label='Nome Completo'
              type='text'
              placeholder='Digite seu nome completo (ex: João da Silva)'
              value={formData.fullName}
              onChange={handleInputChange}
            />
            <InputField
              id='email'
              label='Email Corporativo'
              type='email'
              placeholder='Digite seu email corporativo (ex: joao.silva@empresa.com)'
              value={formData.email}
              onChange={handleInputChange}
            />
            <InputField
              id='phone'
              label='Telefone'
              type='tel'
              placeholder='Digite seu telefone (ex: (00) 12345-6789)'
              value={formData.phone}
              onChange={handleInputChange}
            />
            <button
              type='button'
              onClick={handleNextSection}
              className='rounded-3xl bg-green p-5 text-2xl font-medium text-purple hover:opacity-75'
            >
              {submitButtonText}
            </button>
          </div>
        )}

        {currentSection === 2 && (
          <div className='animate-slideInRight my-4 flex flex-col gap-4'>
            <InputField
              ref={section2FirstInput}
              id='company'
              label='Empresa'
              type='text'
              placeholder='Digite o nome da sua empresa (ex: Empresa XYZ Ltda.)'
              value={formData.company}
              onChange={handleInputChange}
            />
            <InputField
              id='position'
              label='Cargo'
              type='text'
              placeholder='Digite seu cargo na empresa (ex: Gerente de Vendas)'
              value={formData.position}
              onChange={handleInputChange}
            />
            <InputField
              id='segment'
              label='Segmento'
              type='text'
              placeholder='Digite o segmento da empresa (ex: Tecnologia)'
              value={formData.segment}
              onChange={handleInputChange}
            />
            <button
              type='button'
              onClick={handleNextSection}
              className='rounded-3xl bg-green p-5 text-2xl font-medium text-purple hover:opacity-75'
            >
              {submitButtonText}
            </button>
          </div>
        )}

        {currentSection === 3 && (
          <div className='animate-slideInRight my-4 flex flex-col gap-4'>
            <InputField
              ref={section3FirstInput}
              id='employees'
              label='Número de Funcionários'
              type='text'
              placeholder='Digite o número de funcionários'
              value={formData.employees}
              onChange={handleInputChange}
            />
            <InputField
              id='revenue'
              label='Faturamento Anual'
              type='text'
              placeholder='Digite o faturamento anual (ex: R$ 1.000.000,00)'
              value={formData.revenue}
              onChange={handleInputChange}
            />
            <div className='flex flex-col gap-2'>
              <label className='text-xl text-white' htmlFor='message'>
                Mensagem:
              </label>
              <textarea
                className='w-full rounded-3xl p-6 text-lg md:text-xl text-purple border-2 focus:border-green'
                id='message'
                placeholder='Digite sua mensagem'
                value={formData.message}
                onChange={handleInputChange}
              />
            </div>
            <button
              type='submit'
              className='rounded-3xl bg-green p-5 text-2xl font-medium text-purple hover:opacity-75'
            >
              {submitButtonText}
            </button>
          </div>
        )}

        {formError && <span className='text-red-600'>{formError}</span>}
      </form>

      <SuccessModal
        isOpen={formSuccess}
        message={formSuccessMessage}
        error={formError} // Passando formError como propriedade
        onClose={handleCloseModal}
      />
    </section>
  );
}

const InputField = React.forwardRef(({ id, label, type, placeholder, value, onChange }, ref) => (
  <div className='flex flex-col gap-2'>
    <label className='text-lg md:text-xl text-white' htmlFor={id}>
      {label}:
    </label>
    <input
      ref={ref}
      className='w-full rounded-3xl p-6 text-lg md:text-xl text-purple border-2 focus:border-green'
      id={id}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </div>
));

export default ContactForm;