import React, { Link } from 'react-router-dom'
import useScrollVisibility from 'hooks/useScrollVisibility'

function About() {
  const [isVisible, sectionRef] = useScrollVisibility('sobre-nos')

  return (
    <section
      ref={sectionRef}
      id='sobre-nos'
      className={`flex min-h-dvh w-full items-center justify-center ${isVisible ? 'animate-slideInLeft' : 'opacity-0'}`}
    >
      <div className='my-8 flex w-11/12 flex-col gap-6 md:w-9/12'>
        <header className='flex'>
          <h2 className='text-4xl font-semibold uppercase text-white md:text-6xl'>Sobre</h2>
        </header>

        <hr className='my-2 border-2 border-green md:my-4' />

        <p className='text-justify text-lg text-white md:text-2xl'>
          Entendemos que o marketing humanizado não é apenas uma estratégia, mas sim uma filosofia que guia todas as
          nossas ações. Reconhecemos o poder de conectar-se emocionalmente com os clientes, mantendo uma relação
          baseada na autenticidade, na empatia e na ética.
        </p>

        <p className='text-justify text-lg text-white md:text-2xl'>
          Em um mundo frágil e de competição acirrada, defendemos a tese que o conteúdo humanizado, que compreende a
          consciência, os interesses reais em sua essência da sua audiência, é a chave para construir uma experiência
          significativa e de confiança com o público.
        </p>

        <p className='text-justify text-lg text-white md:text-2xl'>
          Na Agência Dorac, estamos comprometidos em proporcionar uma experiência única e valiosa para cada cliente,
          utilizando nosso conhecimento e expertise com o apoio de um método exclusivo para ajudá-los a alcançar seus
          objetivos de forma eficaz.
        </p>

        <button className='flex items-center justify-center'>
          <Link
            to='/sobre'
            className='rounded-full bg-green px-10 py-3 text-2xl font-medium uppercase text-purple hover:opacity-75'
          >
            Saiba Mais
          </Link>
        </button>
      </div>
    </section>
  )
}

export default About
