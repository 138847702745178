import React from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/icons/logo.svg'
import email from 'assets/icons/email.svg'
import whatsapp from 'assets/icons/whatsapp.svg'

function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <footer
      id='contato'
      className='flex flex-col items-center justify-center  md:min-h-[50vh]'
    >
      <div className='m-8 flex w-11/12 flex-col justify-between gap-8 text-white md:w-10/12 md:flex-row'>
        <picture className='items-center-center flex flex-1'>
          <img
            src={logo}
            alt='Logo da Agência Dorac, formado pelas letras A e D estilizadas'
            loading='lazy'
            className='w-full md:w-11/12'
          />
        </picture>

        <div className='flex flex-1 flex-col gap-4'>
          <header>
            <h3 className='text-2xl font-semibold uppercase'>Serviços</h3>
          </header>

          <ul className='flex flex-col gap-2'>
            <li className='text-xl'>
              <Link to='/servicos' className='transition-colors duration-500 hover:text-green'>
                Branding e Id visual
              </Link>
            </li>
            <li className='text-xl'>
              <Link to='/servicos' className='transition-colors duration-500 hover:text-green'>
                Mídias Sociais
              </Link>
            </li>
            <li className='text-xl'>
              <Link to='/servicos' className='transition-colors duration-500 hover:text-green'>
                Sites e E-commerces
              </Link>
            </li>
            <li className='text-xl'>
              <Link to='/servicos' className='transition-colors duration-500 hover:text-green'>
                Inbound Marketing
              </Link>
            </li>
          </ul>
        </div>

        <div className='flex flex-1 flex-col gap-4'>
          <header>
            <h3 className='text-2xl font-semibold uppercase'>Redes Sociais</h3>
          </header>

          <ul className='flex flex-col gap-2'>
            <li className='text-xl'>
              <a
                href='https://www.instagram.com/agenciadorac/'
                className='transition-colors duration-500 hover:text-green'
                target='_blank'
              >
                Instagram
              </a>
            </li>
            <li className='text-xl'>
              <a
                href='https://www.facebook.com/agenciadorac/'
                className='transition-colors duration-500 hover:text-green'
                target='_blank'
              >
                Facebook
              </a>
            </li>
            <li className='text-xl'>
              <a
                href='https://www.linkedin.com/company/agenciadorac/'
                className='transition-colors duration-500 hover:text-green'
                target='_blank'
              >
                Linkedin
              </a>
            </li>
            <li className='text-xl'>
              <a
                href='https://www.youtube.com/@agenciadorac/'
                className='transition-colors duration-500 hover:text-green'
                target='_blank'
              >
                Youtube
              </a>
            </li>
            <li className='text-xl'>
              <a
                href='https://www.tiktok.com/@agenciadorac/'
                className='transition-colors duration-500 hover:text-green'
                target='_blank'
              >
                Tiktok
              </a>
            </li>
          </ul>
        </div>

        <div className='flex flex-1 flex-col gap-4'>
          <div className='flex flex-1 flex-col gap-4'>
            <header>
              <h3 className='text-2xl font-semibold uppercase'>Links Úteis</h3>
            </header>

            <ul className='flex flex-col gap-2'>
              <li className='text-xl'>
                <a href='#' className='transition-colors duration-500 hover:text-green'>
                  Política de Privacidade
                </a>
              </li>
              <li className='text-xl'>
                <a href='#' className='transition-colors duration-500 hover:text-green'>
                  Trabalhe Conosco
                </a>
              </li>
              <li className='text-xl'>
                <a href='#' className='transition-colors duration-500 hover:text-green'>
                  Suporte
                </a>
              </li>
            </ul>
          </div>

          <div className='flex flex-1 flex-col gap-4'>
            <header>
              <h3 className='text-2xl font-semibold uppercase'>Contato</h3>
            </header>

            <ul className='flex flex-col gap-2'>
              <li className='text-xl'>
                <a
                  href='mailto:samantha@dorac.com.br'
                  className='flex items-center gap-2 transition-colors duration-500 hover:text-green'
                  target='_blank'
                >
                  <img src={email} alt='Ícone do email' className='h-8 w-8' />
                  <span>samantha@dorac.com.br</span>
                </a>
              </li>
              <li className='text-xl'>
                <a
                  href='https://api.whatsapp.com/send?phone=5543999024775'
                  className='flex items-center gap-2 transition-colors duration-500 hover:text-green'
                  target='_blank'
                >
                  <img src={whatsapp} alt='Ícone do whatsApp' className='h-8 w-8' />
                  <span>(43) 99902-4775</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='flex w-full items-center justify-center bg-white p-4'>
        <blockquote className='text-lg font-medium text-purple'>
          &copy; {currentYear} Agência Dorac. Todos os direitos reservados.
        </blockquote>
      </div>
    </footer>
  )
}

export default Footer
