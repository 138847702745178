import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useScrollVisibility from 'hooks/useScrollVisibility'

const data = [
  {
    id: 'branding-id-visual',
    name: 'Branding e Id Visual',
    description:
      'Desenvolvemos identidades visuais únicas que representam a essência da sua marca, criando designs memoráveis e consistentes.',
  },
  {
    id: 'midias-sociais',
    name: 'Mídias Sociais',
    description:
      'Gerenciamos suas redes sociais de forma estratégica, aumentando o engajamento do público-alvo e fortalecendo a presença digital da sua marca.',
  },
  {
    id: 'websites',
    name: 'Sites e E-commerces',
    description:
      'Criamos websites responsivos e e-commerces otimizados, proporcionando uma experiência de navegação intuitiva e segura para os seus clientes.',
  },
  {
    id: 'inbound-marketing',
    name: 'Inbound Marketing',
    description:
      'Atraímos, convertemos e fidelizamos clientes através de conteúdos relevantes e estratégias personalizadas de marketing digital.',
  },
]

function ServiceItem({ service }) {
  const [isHovered, setIsHovered] = useState(false)
  const [isVisible, sectionRef] = useScrollVisibility('servicos')

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <li
      ref={sectionRef}
      className='relative flex min-h-56 w-full cursor-pointer items-center justify-center overflow-hidden rounded-3xl border-2 border-yellow bg-purple bg-opacity-75 p-4 backdrop-blur-md backdrop-filter md:w-2/5'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* <Link to={`/servico/${service.id}`}> */}
      <div className='text-center text-2xl font-semibold uppercase text-white md:text-3xl'>{service.name}</div>
      <div
        className={`absolute bottom-0 left-0 right-0 flex h-full w-full items-center justify-center bg-purple text-white transition-opacity duration-1000 ease-in-out ${isHovered ? 'opacity-100' : 'opacity-0'}`}
      >
        <p className='w-10/12 text-justify text-lg text-white md:text-center'>{service.description}</p>
      </div>
      {/* </Link> */}
    </li>
  )
}

function OurServices() {
  const [isVisible, sectionRef] = useScrollVisibility('servicos')

  return (
    <section ref={sectionRef} id='servicos' className='flex min-h-screen items-center justify-center bg-opacity-50'>
      <div className='my-8 flex w-11/12 flex-col items-center justify-center gap-6'>
        <header
          className={`flex flex-col items-center justify-center gap-4 text-center ${isVisible ? 'animate-scaleIn' : 'opacity-0'}`}
        >
          <h2 className='text-4xl font-semibold uppercase text-white md:text-6xl'>Nossos Serviços</h2>
          <p className='text-lg font-medium text-green md:w-2/3 md:text-2xl'>
            Conheça as soluções que a nossa <span className='underline'>agência de marketing</span> pode oferecer para
            alavancar o seu negócio.
          </p>
        </header>

        <ul
          className={`flex flex-wrap items-center justify-center gap-4 ${isVisible ? 'animate-scaleIn' : 'opacity-0'}`}
        >
          {data.map((service, index) => (
            <ServiceItem key={index} service={service} />
          ))}
        </ul>
      </div>
    </section>
  )
}

export default OurServices
