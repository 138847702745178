import { useEffect } from 'react'
import Header from 'components/Header'
import OurServices from './components/OurServices'
import Footer from 'components/Footer'
import Whatsapp from 'components/Whatsapp'

function Services() {
  useEffect(() => {
    document.title = 'Dorac Brasil - Serviços'
    window.scrollTo(0, 0)
  }, [])

  return (
    <main className='bg-blue bg-opacity-90'>
      <Header />
      <OurServices />
      <Footer />
      <Whatsapp />
    </main>
  )
}

export default Services
