import React from 'react'
import { Link } from 'react-router-dom'

function AboutUs() {
  return (
    <section className='flex w-full items-center justify-center text-justify text-xl md:text-2xl text-white'>
      <div className='flex w-11/12 flex-col gap-8 mt-36 md:mt-48'>
        <nav className='flex items-center justify-start gap-2 text-xl text-white'>
          <Link to='/' className='hovertext-green transition-colors duration-500'>
            Inicio
          </Link>
          <span className=''>/</span>
          <Link to='/sobre' className='hovertext-green transition-colors duration-500'>
            Sobre
          </Link>
        </nav>

        <section className='flex w-full flex-col justify-between gap-8'>
          <header>
            <h1 className="text-5xl font-medium">
            Sobre
            </h1>
          </header>
          <article className='flex flex-1 flex-col gap-4'>
            <header className='text-xl md:text-2xl font-medium'>Meus Caros,</header>

            <div className='flex flex-col gap-4'>
              <p className='text-justify text-xl md:text-2xl'>
                Dorac é mais do que uma simples palavra, é um movimento que representa nossa abordagem única para
                definir processos que buscam ressignificar toda a experiência humana em contato com nossas
                campanhas.
              </p>

              <p className='text-justify text-xl md:text-2xl'>
                Para nós, Dorac é sinônimo de essencialismo – em um mundo voraz, ressignificar torna-se mais do que uma
                opção, torna-se uma necessidade. Em tudo o que fazemos estamos comprometidos em oferecer experiências
                que tocam os sentidos e despertam a consciência.
              </p>

              <p className='text-justify text-xl md:text-2xl'>
                Dorac não é apenas um método, é uma filosofia e uma cultura que nos influencia e impacta todos os que entram em contato conosco.
              </p>

              <p className='text-justify text-xl md:text-2xl'>
                Sou incrivelmente orgulhosa do trabalho que fazemos aqui na Dorac e do impacto positivo que estamos
                causando. Juntos, estamos transformando a maneira como as pessoas percebem e interagem com o novo
                marketing.
              </p>
            </div>

            <blockquote className='text-lg font-medium'>Abraços, Dra. Samantha Dorabiato</blockquote>
          </article>

          <article className='flex flex-col gap-4 w-full'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className='flex cursor-pointer flex-col justify-center gap-4 rounded-xl border-4 border-green bg-purple bg-opacity-50 p-8 backdrop-blur-xl'>
              <h2 className='text-4xl font-medium'>Missão</h2>
              <p>
                Transformar a inquietude das relações humanas na mudança do status quo.
              </p>
            </div>

            <div className='flex cursor-pointer flex-col justify-center gap-4 rounded-xl border-4 border-green bg-purple bg-opacity-50 p-8 backdrop-blur-xl'>
              <h2 className='text-4xl font-medium'>Visão</h2>
              <p>
                Ser reconhecido nacionalmente até 2026 como agência pioneira, conhecida por nossa abordagem
                humanizada e exclusiva.
              </p>
            </div>
            </div>
            <div className='flex cursor-pointer flex-col justify-center gap-4 rounded-xl border-4 border-green bg-purple bg-opacity-50 p-8 backdrop-blur-xl'>
              <h2 className='text-4xl font-medium'>Valores</h2>
              <ul className='flex flex-col gap-2'>
                <li className='ms-4 list-disc'>
                  <strong className="font-medium">Autenticidade:</strong> Valorizamos a originalidade e a coragem de sermos pioneiros.
                </li>
                <li className='ms-4 list-disc'>
                  <strong className="font-medium">Empatia:</strong> Colocamos as pessoas no centro de tudo o que fazemos.
                </li>
                <li className='ms-4 list-disc'>
                  <strong className="font-medium">Transparência:</strong> A confiança é construída por meio de honestidade e da clareza em
                  nossos processos.
                </li>
                <li className='ms-4 list-disc'>
                  <strong className="font-medium">Excelência:</strong> Comprometimento com a melhor entrega em todos os aspectos do nosso
                  trabalho.
                </li>
                <li className='ms-4 list-disc'>
                  <strong className="font-medium">Colaboração:</strong> Acreditamos que ideias brilhantes surgem da diversidade de pensamentos e
                  experiências.
                </li>
              </ul>
            </div>
          </article>
        </section>
      </div>
    </section>
  )
}

export default AboutUs
