import React from 'react'
import logo from 'assets/icons/logo.svg'
function Banner() {
  return (
    <section id='home' className='flex w-full items-center justify-center'>
      <div className='mt-44 flex w-11/12 items-center justify-center text-white md:mt-20 md:min-h-screen md:w-10/12'>
        <div className='flex w-full flex-1 animate-slideInLeft flex-col gap-4'>
          <h1 className='text-5xl  font-semibold md:w-10/12 md:text-6xl'>
            Marketing Digital <br /> Humanizado
          </h1>
          <p className='text-justify text-xl font-medium md:w-10/12 md:text-2xl'>
            Utilizamos o marketing humanizado para acelerar campanhas, implementando estratégias autênticas e
            inovadoras.
          </p>
          <span className='text-justify text-xl font-semibold text-green md:w-10/12 md:text-3xl'>
            A Primeira do Brasil com Metodologia Exclusiva!
          </span>
          <button className='flex'>
            <a
              href='https://api.whatsapp.com/send/?phone=5543999024775&text=Ol%C3%A1%21+Como+posso+te+ajudar%3F&type=phone_number&app_absent=0'
              target='_blank'
              className='rounded-full bg-white px-10 py-3 text-2xl font-medium uppercase text-purple hover:opacity-75 md:w-1/3'
            >
              Confira
            </a>
          </button>
        </div>

        
        <picture className='hidden md:flex flex-1 animate-slideInRight'>
          <img src={logo} alt='Logo da Agência Dorac, formado pelas letras A e D estilizadas' className='w-11/12' />
        </picture>
      </div>
    </section>
  )
}

export default Banner
