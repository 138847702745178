import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const data = [
  {
    id: 'branding-id-visual',
    name: 'Branding Id Visual',
    description:
      'Desenvolvemos identidades visuais únicas que representam a essência da sua marca, criando designs memoráveis e consistentes.',
  },
  {
    id: 'midias-sociais',
    name: 'Mídias Sociais',
    description:
      'Gerenciamos suas redes sociais de forma estratégica, aumentando o engajamento do público-alvo e fortalecendo a presença digital da sua marca.',
  },
  {
    id: 'websites',
    name: 'Sites E-commerces',
    description:
      'Criamos websites responsivos e e-commerces otimizados, proporcionando uma experiência de navegação intuitiva e segura para os seus clientes.',
  },
  {
    id: 'inbound-marketing',
    name: 'Inbound Marketing',
    description:
      'Atraímos, convertemos e fidelizamos clientes através de conteúdos relevantes e estratégias personalizadas de marketing digital.',
  },
]

function ServiceItem({ service: { name, description } }) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <li
      className='relative flex min-h-56 w-full cursor-pointer items-center justify-center overflow-hidden rounded-3xl border-2 border-yellow bg-purple bg-opacity-75 p-4 backdrop-blur-md backdrop-filter md:w-2/5'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='text-center text-2xl font-semibold uppercase text-white md:text-3xl'>{name}</div>
      <div
        className={`absolute bottom-0 left-0 right-0 flex h-full w-full items-center justify-center bg-purple text-white transition-opacity duration-1000 ease-in-out ${
          isHovered ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <p className='w-10/12 text-justify text-lg text-white md:text-center'>{description}</p>
      </div>
    </li>
  )
}

function OurServices() {
  return (
    <section className='flex min-h-screen flex-col items-center justify-center'>
      <div className='m-4 flex w-11/12 flex-col gap-6 md:w-10/12 mt-36 md:mt-48'>
        <nav className='flex items-center justify-start gap-2 text-xl text-white'>
          <Link to='/' className='transition-colors duration-500 hover:text-green'>
            Inicio
          </Link>
          <span className=''>/</span>
          <Link to='/servicos' className='transition-colors duration-500 hover:text-green'>
            Serviços
          </Link>
        </nav>

        <div className='flex flex-col items-center justify-center gap-6'>
          <header className='flex animate-scaleIn flex-col items-center justify-center gap-4 text-center'>
            <h2 className='text-4xl font-semibold uppercase text-white md:text-6xl'>Nossos Serviços</h2>
            <p className='text-lg font-medium text-green md:w-2/3 md:text-2xl'>
              Conheça as soluções que a nossa <span className='underline'>agência de marketing</span> pode oferecer para
              alavancar o seu negócio.
            </p>
          </header>

          <ul className='flex animate-scaleIn flex-wrap items-center justify-center gap-4'>
            {data.map((service) => (
              <ServiceItem key={service.id} service={service} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default OurServices
