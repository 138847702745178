import React, { useEffect } from 'react'
import Header from 'components/Header'
import Banner from './components/Banner'
import AboutUs from './components/AboutUs'
import Footer from 'components/Footer'
import Whatsapp from 'components/Whatsapp'

function About() {
  useEffect(() => {
    document.title = 'Dorac Brasil - Sobre'
    window.scrollTo(0, 0)
  }, [])

  return (
    <main className='bg-blue bg-opacity-90'>
      <Header />
      <AboutUs />
      <Whatsapp />
      <Footer />
    </main>
  )
}

export default About
